import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "react-oidc-context";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { lightTheme } from "./Themes/LightTheme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./Redux/Store/store";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

//const oidcConfig = {
//  authority: "https://myid-qa.siemens.com:443",
//  client_id: "677519a0-ee20-4f8f-bc2c-ec6108d58b50", //92ac572c-5986-405c-a5f9-a483aa63f24e
//  client_secret: "nf3DNf3wKagL11FJGU", //5n2uEgAls8hbZuj6ze
//  redirect_uri: `${window.location.origin}`,
//  response_type: "code",
//  post_logout_redirect_uri: `${window.location.origin}`,
//  silent_redirect_uri: `${window.location.origin}`,
//  automaticSilentRenew: true,
//  requireHttpsMetadata: false,
//  getClaimsFromUserInfoEndpoint: true,
//};

const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (msalInstance.getActiveAccount() != null && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

document.body.style.zoom = `90%`;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </AuthProvider>
      </MsalProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);