import { Switch, Route, Redirect } from "react-router-dom";
import OnlinePactHome from "../Screens/Sales/OnlinePact/OP_Home/OP_HomePage";
import CreateComplaint from "../Screens/Sales/OnlinePact/CreateComplaint/Create_Complaint";
import CloseComplaint from "../Screens/Sales/OnlinePact/CloseComplaint/Close_Complaint";
import CloseComplaintConfirmation from "../Screens/Sales/OnlinePact/CloseComplaint/Close_Complaint_Confirmation";
import Reports from "../Screens/Sales/OnlinePact/Reports/Reports";
import CloseComplaintRoot from "../Screens/Sales/OnlinePact/CloseComplaint/CloseComplaintRoot";
import NewHome from "../Screens/Home/NewHome";
import EscalateComplaint from "../Screens/Sales/OnlinePact/EscalateComplaint/Escalate_Complaint";
import EscalateComplaintRoot from "../Screens/Sales/OnlinePact/EscalateComplaint/EscalateComplaintRoot";
import EscalateConfirmation from "../Screens/Sales/OnlinePact/EscalateComplaint/EscalateConfirmation";
import DelegateComplaint from "../Screens/Sales/OnlinePact/DelegateComplaint/DelegateComplaint";
import DelegateComplaintRoot from "../Screens/Sales/OnlinePact/DelegateComplaint/DelegateComplaintRoot";
import DelegateUser from "../Screens/Sales/OnlinePact/DelegateComplaint/DelegateUser";
import DispatchMaster from "../Screens/Administrator/DispatchCenterMaster/DispatchCenterMaster";
import ReportRoot from "../Screens/Sales/OnlinePact/Reports/ReportRoot";
import UserManagementNew from "../Screens/Administrator/UserManagement/UserManagementNew";
import UnauthorizedAccess from "../components/UnauthorizedAccess/UnauthorizedAccess";

export const MasterRoute = (
    <Switch>
        <Redirect from="/?code*" to="/" />
        <Route exact path="/" render={() => <NewHome />} />
        <Route exact path="/NewHome" render={() => <NewHome />} />
        <Route exact path="/userManagement" render={() => <UserManagementNew />} />
        <Route
            exact
            path="/dispatchCenterMaster"
            render={() => <DispatchMaster />}
        />
        <Route exact path="/complaintList" render={() => <OnlinePactHome />} />

        <Route exact path="/createcomplaint" render={() => <CreateComplaint />} />
        <Route exact path="/closecomplaint" render={() => <CloseComplaint />} />
        <Route exact path="/reports" render={() => <Reports />} />
        <Route exact path="/detailedReport" render={() => <ReportRoot />} />
        <Route
            exact
            path="/escalatecomplaint"
            render={() => <EscalateComplaint />}
        />
        <Route
            exact
            path="/closecomplaintroot"
            render={() => <CloseComplaintRoot />}
        />
        <Route
            exact
            path="/escalatecomplaintroot"
            render={() => <EscalateComplaintRoot />}
        />
        <Route
            exact
            path="/escalateconfirmation"
            render={() => <EscalateConfirmation />}
        />
        <Route
            exact
            path="/closecomplaintdetail"
            render={() => <CloseComplaintConfirmation />}
        />
        <Route
            exact
            path="/DelegateComplaint"
            render={() => <DelegateComplaint />}
        />
        <Route
            exact
            path="/DelegateComplaintRoot"
            render={() => <DelegateComplaintRoot />}
        />
        <Route exact path="/DelegateUser" render={() => <DelegateUser />} />
        <Route path="*" render={() => <UnauthorizedAccess />} />
    </Switch>
);

export const RecoTeamRoute = (
    <Switch>
        <Redirect from="/?code*" to="/" />
        <Route exact path="/" render={() => <NewHome />} />
        <Route exact path="/NewHome" render={() => <NewHome />} />
        <Route exact path="/dispatchCenterMaster" render={() => <DispatchMaster />} />
        <Route exact path="/closecomplaint" render={() => <CloseComplaint />} />
        <Route exact path="/reports" render={() => <Reports />} />
        <Route exact path="/detailedReport" render={() => <ReportRoot />} />
        <Route exact path="/closecomplaintroot" render={() => <CloseComplaintRoot />} />
        <Route exact path="/closecomplaintdetail" render={() => <CloseComplaintConfirmation />} />
        <Route exact path="/DelegateComplaint" render={() => <DelegateComplaint />} />
        <Route exact path="/DelegateComplaintRoot" render={() => <DelegateComplaintRoot />} />
        <Route exact path="/DelegateUser" render={() => <DelegateUser />} />
        <Route path="*" render={() => <UnauthorizedAccess />} />
    </Switch>
);

export const OrderManagementRoute = (
    <Switch>
        <Redirect from="/?code*" to="/" />
        <Route exact path="/" render={() => <NewHome />} />
        <Route exact path="/NewHome" render={() => <NewHome />} />
        <Route exact path="/userManagement" render={() => <UserManagementNew />} />
        <Route exact path="/dispatchCenterMaster" render={() => <DispatchMaster />} />
        <Route exact path="/complaintList" render={() => <OnlinePactHome />} />
        <Route exact path="/createcomplaint" render={() => <CreateComplaint />} />
        <Route exact path="/closecomplaint" render={() => <CloseComplaint />} />
        <Route exact path="/reports" render={() => <Reports />} />
        <Route exact path="/detailedReport" render={() => <ReportRoot />} />
        <Route exact path="/escalatecomplaint" render={() => <EscalateComplaint />} />
        <Route exact path="/closecomplaintroot" render={() => <CloseComplaintRoot />} />
        <Route exact path="/escalatecomplaintroot" render={() => <EscalateComplaintRoot />} />
        <Route exact path="/escalateconfirmation" render={() => <EscalateConfirmation />} />
        <Route exact path="/closecomplaintdetail" render={() => <CloseComplaintConfirmation />} />
        <Route exact path="/DelegateComplaint" render={() => <DelegateComplaint />} />
        <Route exact path="/DelegateComplaintRoot" render={() => <DelegateComplaintRoot />} />
        <Route exact path="/DelegateUser" render={() => <DelegateUser />} />
        <Route path="*" render={() => <UnauthorizedAccess />} />
    </Switch>
);