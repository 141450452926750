export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};

export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const isUserAuthorizedAction = (userData) => {
  return {
    type: "isUserAuthorized",
    payLoad: userData,
  };
};
export const storeUpdatePotentialResponse = (response) => {
  return {
    type: "updatePotential",
    payLoad: 1,
  };
};
export const storeUpdateBudgetResponse = (response) => {
  return {
    type: "updateBudget",
    payLoad: 2,
  };
};
export const storeUpdateManualIndirectResponse = (response) => {
  return {
    type: "updateManualIndirect",
    payLoad: 3,
  };
};
export const complaintDetailDataAction = (compData) => {
  return {
    type: "complaintDetailData",
    payLoad: compData,
  };
};
export const closingComDetailsAction = (closeDtlObj) => {
  return {
    type: "closeDtlObj",
    payLoad: closeDtlObj,
  };
};
export const escalateRemarksAction = (escalateRem) => {
  return {
    type: "escalateRem",
    payLoad: escalateRem,
  };
};
export const detReportsAction = (detReports) => {
  return {
    type: "detReports",
    payLoad: detReports,
  };
};
export const saveAzureTokenAction = (token) => {
    return {
        type: "saveAzureToken",
        payLoad: token,
    };
};
export const saveUserIdAction = (id) => {
  return {
    type: "encryptedId",
    payLoad: id,
  };
};