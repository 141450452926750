import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  DialogTitle,
  TextField,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import "../CloseComplaint/Close_Complaint.scss";
import { tableIconsInputScreen } from "../../../../Utilities/Utility";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OnlinePactNavBar from "../OnlinePactNavBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DisplayMultipleAttachment,
  getAttachments,
} from "../../../../components/CustomComponents/CustomComponents";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function DelegateComplaintRoot() {
  const [activeStep, setActiveStep] = React.useState(0);
  const getSteps = () => {
    return ["Complaint Details", "MLFB Details", "Delegate To"];
  };
  const steps = getSteps();

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 2) {
      // dispatch(
      //   escalateRemarksAction({
      //     remarks: escalateRemarks,
      //   })
      // );
    }
  };
  const handleStepperDelegateSubmit = () => {
    console.log("Delegate Button Clicked");
  };

  const compRowData = useSelector(
    (state) => state.complaintDetailDataReducer.complaintDetailData
  );
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const UID = useSelector((state) => state.saveUserIdReducer);

  const history = useHistory();
  const api = axios.create({
    baseURL: "CloseComplaint",
  });
  const EscalateApi = axios.create({
    baseURL: "Escalation",
  });

  useEffect(() => {
    getDelegateUsers();
    getAttachmentsFromID();
    console.log("compRowData", compRowData);
  }, []);

  const getDelegateUsers = () => {
    delegateAPI
      .get("/UsersDelegate", {
        headers: {
          Authorization: "Bearer " + siteToken,
          UserEmail: userDetails.email,
          userId: UID.id,
          RoleName: userDetails.roleName,
          Gid: userDetails.gid,
          Roleid: userDetails.roleId,
          AzureToken: azureToken.token
        }
      })
      .then((res) => {
        console.log("Delegate User", res);
        setDelegateUser(res.data);
      })
      .catch((err) => {
        console.log("Delegate Error", err);
      });
  };

  let saveStatusArgs = {};
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
    sorting: true,
  };
  const closeCompDtlColumn = [
    { title: "Invoice Position", field: "item" },
    { title: "MLFB", field: "mlfb" },
    { title: "Quantity", field: "quantity" },
    { title: "Order No", field: "orderNo" },
    { title: "Order Item", field: "orderItem" },
    { title: "BSS", field: "bss" },
    { title: "BU", field: "bu" },
    { title: "Affected MLFB", field: "enteredMlfb" },
    { title: "Affected Qty", field: "enteredQuantity" },
  ];

  const classes = useStyles();

  const [delegateUser, setDelegateUser] = useState([]);
  const [delegateUserUI, setDelegateUserUI] = useState([]);

  const [escalateRemarks, setEscalateRemarks] = useState("");

  const escalateRemarksChange = (e) => {
    setEscalateRemarks(e.target.value);
  };
  const handleDelegateSubmit = () => {
    console.log("Delegate Submit", saveDelegateArgs);
    if (saveDelegateArgs.UserId !== null && saveDelegateArgs.UserId !== "") {
      setIsLoading(true);
      delegateAPI
        .post("/DelegateComplaint", saveDelegateArgs, {
          headers: {
            Authorization: "Bearer " + siteToken,
            UserEmail: userDetails.email,
            userId: UID.id,
            RoleName: userDetails.roleName,
            Gid: userDetails.gid,
            Roleid: userDetails.roleId,
            AzureToken: azureToken.token
          }
        })
        .then((res) => {
          setIsLoading(false);
          console.log("Response Delegate submit", res);
          handleSnackOpen("Delegation is Successfull", "success");
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error", err);
          handleSnackOpen("Delegation Failed", "error");
        });
    } else {
      handleSnackOpen("Select Delegate User", "warning");
    }
  };

  const handleCancelSubmit = () => {
    console.log("Cancel");
    history.goBack();
  };

  const delegateAPI = axios.create({
    baseURL: "Delegate",
  });

  const delegateUserChange = (e, value) => {
    setDelegateUserUI(value);
    setSaveDelegateArgs((saveDelegateArgs) => ({
      ...saveDelegateArgs,
      UserId: value != null ? value.id : "",
    }));
  };

  const [saveDelegateArgs, setSaveDelegateArgs] = useState({
    ComplaintId: compRowData.complaintId,
    UserId: "",
    ComplaintNumber: compRowData.complaintNumber,
    loginId: userDetails.loginId,
  });

  const [createCompAttchments, setCreateCompAttchments] = useState([]);
  async function getAttachmentsFromID() {
    let complaintNor = { ComplaintNumber: compRowData.complaintNumber };
    const response = await getAttachments(complaintNor, siteToken, userDetails, UID, azureToken);
    if (response.status == 200) {
      setCreateCompAttchments(response.data.tempAttachmentDetails);
    } else {
      handleSnackOpen("Error in fetching Data !!", "error");
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="complaintNumber"
                  label="Complaint Number"
                  variant="filled"
                  type="text"
                  value={compRowData.complaintNumber}
                  // onChange={}
                  id="complaintNumber"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="rollNumber"
                  label="Roll Number"
                  variant="filled"
                  type="text"
                  value={compRowData.rollNumber}
                  // onChange={}
                  id="rollNumber"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="name"
                  label="Name"
                  variant="filled"
                  type="text"
                  value={compRowData.name}
                  // onChange={}
                  id="name"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="department"
                  label="Department"
                  variant="filled"
                  type="text"
                  value={compRowData.departmentName}
                  // onChange={}
                  id="department"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="phoneNumber"
                  label="Phone Number"
                  variant="filled"
                  type="text"
                  value={compRowData.phoneNumber}
                  // onChange={}
                  id="phoneNumber"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="salesOffice"
                  label="Sales Office"
                  variant="filled"
                  type="text"
                  value={compRowData.salesOffice}
                  // onChange={}
                  id="salesOffice"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="email"
                  label="Email"
                  variant="filled"
                  type="text"
                  value={compRowData.email}
                  // onChange={}
                  id="email"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="referenceNumber"
                  label="Reference Number"
                  variant="filled"
                  type="text"
                  value={compRowData.referenceName}
                  // onChange={}
                  id="referenceNumber"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="invoiceNumber"
                  label="Invoice Number"
                  variant="filled"
                  type="text"
                  value={compRowData.invoiceNo}
                  // onChange={}
                  id="invoiceNumber"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="reason"
                  label="Reason"
                  variant="filled"
                  type="text"
                  value={compRowData.reasonName}
                  // onChange={}
                  id="reason"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="dispatchCenter"
                  label="Dispatch Center"
                  variant="filled"
                  type="text"
                  value={compRowData.dispatchCenterName}
                  // onChange={}
                  id="dispatchCenter"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="customer"
                  label="Customer"
                  variant="filled"
                  type="text"
                  value={compRowData.customerName}
                  // onChange={}
                  id="customer"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="division"
                  label="Division"
                  variant="filled"
                  type="text"
                  value={compRowData.division}
                  // onChange={}
                  id="division"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="transporterName"
                  label="Transporter Name"
                  variant="filled"
                  type="text"
                  value={compRowData.transporter}
                  // onChange={}
                  id="transporterName"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="lrNo"
                  label="LR No"
                  variant="filled"
                  type="text"
                  value={compRowData.lrno}
                  // onChange={}
                  id="lrNo"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="lrDate"
                  label="LR Date"
                  variant="filled"
                  type="text"
                  value={compRowData.lrdate}
                  // onChange={}
                  id="lrDate"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="seEmail"
                  label="SE Email"
                  variant="filled"
                  type="text"
                  value={compRowData.seemail}
                  // onChange={}
                  id="seEmail"
                />
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <TextField
                  className=""
                  fullWidth
                  disabled
                  name="remarks"
                  label="Remarks"
                  variant="filled"
                  type="text"
                  multiline="true"
                  minRows="4"
                  maxRows="4"
                  value={compRowData.remarks}
                  // onChange={}
                  id="remarks"
                />
              </Grid>
            </Grid>

            {createCompAttchments.length > 0 ? (
              <DisplayMultipleAttachment tableData={createCompAttchments} />
            ) : (
              <></>
            )}
          </>
        );
      case 1:
        return (
          <MaterialTable
            title="User List"
            columns={closeCompDtlColumn}
            data={compRowData.invoiceDetails}
            icons={tableIconsInputScreen}
            //isLoading={isLoader}
            options={tableOptions}
          />
        );
      case 2:
        return (
          <>
            <Grid container spacing={2} className="mt-05">
              <Grid item xs={12} sm={4} md={3} className="customerField">
                <Autocomplete
                  options={delegateUser}
                  getOptionLabel={(option) => {
                    if (option.firstName != undefined)
                      return `${option.firstName} ${option.lastName}`;
                    else return "";
                  }}
                  id="debug"
                  clearOnEscape={true}
                  debug
                  value={delegateUserUI}
                  onChange={delegateUserChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Delagate User"
                      margin="none"
                    />
                  )}
                />
              </Grid>
              {/* <Grid
                container
                spacing={2}
                className="jc-center d-flex mt-1 buttonContainer marginBtm"
              >
                <Grid item xs={10} sm={4} md={3}>
                  <Button
                    fullWidth
                    onClick={handleDelegateSubmit}
                    className="pt-button--primary mt-1"
                  >
                    Delegate
                  </Button>
                </Grid>

                <Grid item xs={10} sm={4} md={3}>
                  <Button
                    fullWidth
                    onClick={handleCancelSubmit}
                    className="pt-button--secondary mt-1"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <>
      <OnlinePactNavBar />

      <div className="stepperCont">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <Grid
                container
                spacing={2}
                className="jc-center d-flex mt-1 gridMargin"
              >
                <Grid item xs={10} sm={4} md={2}>
                  <Button
                    fullWidth
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className="pt-button--secondary mt-1"
                  >
                    Back
                  </Button>
                </Grid>
                {activeStep === steps.length - 1 ? (
                  <Grid item xs={10} sm={4} md={2}>
                    <Button
                      fullWidth
                      onClick={handleDelegateSubmit}
                      className="pt-button--primary mt-1"
                    >
                      Delegate
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={10} sm={4} md={2}>
                    <Button
                      fullWidth
                      onClick={handleNext}
                      className="pt-button--primary mt-1"
                    >
                      Next
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </div>
      </div>

      {/* <div className="paddingBtm borderBtm">
        <DialogTitle id="responsive-dialog-title">
          Complaint Details
        </DialogTitle>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="complaintNumber"
              label="Complaint Number"
              variant="filled"
              type="text"
              value={compRowData.complaintNumber}
              // onChange={}
              id="complaintNumber"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="rollNumber"
              label="Roll Number"
              variant="filled"
              type="text"
              value={compRowData.rollNumber}
              // onChange={}
              id="rollNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="name"
              label="Name"
              variant="filled"
              type="text"
              value={compRowData.name}
              // onChange={}
              id="name"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="department"
              label="Department"
              variant="filled"
              type="text"
              value={compRowData.departmentName}
              // onChange={}
              id="department"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="phoneNumber"
              label="Phone Number"
              variant="filled"
              type="text"
              value={compRowData.phoneNumber}
              // onChange={}
              id="phoneNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="salesOffice"
              label="Sales Office"
              variant="filled"
              type="text"
              value={compRowData.salesOffice}
              // onChange={}
              id="salesOffice"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="email"
              label="Email"
              variant="filled"
              type="text"
              value={compRowData.email}
              // onChange={}
              id="email"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="referenceNumber"
              label="Reference Number"
              variant="filled"
              type="text"
              value={compRowData.referenceName}
              // onChange={}
              id="referenceNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="invoiceNumber"
              label="Invoice Number"
              variant="filled"
              type="text"
              value={compRowData.invoiceNo}
              // onChange={}
              id="invoiceNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="reason"
              label="Reason"
              variant="filled"
              type="text"
              value={compRowData.reasonName}
              // onChange={}
              id="reason"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="dispatchCenter"
              label="Dispatch Center"
              variant="filled"
              type="text"
              value={compRowData.dispatchCenterName}
              // onChange={}
              id="dispatchCenter"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="customer"
              label="Customer"
              variant="filled"
              type="text"
              value={compRowData.customerName}
              // onChange={}
              id="customer"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="division"
              label="Division"
              variant="filled"
              type="text"
              value={compRowData.division}
              // onChange={}
              id="division"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="transporterName"
              label="Transporter Name"
              variant="filled"
              type="text"
              value={compRowData.transporter}
              // onChange={}
              id="transporterName"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="lrNo"
              label="LR No"
              variant="filled"
              type="text"
              value={compRowData.lrno}
              // onChange={}
              id="lrNo"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="lrDate"
              label="LR Date"
              variant="filled"
              type="text"
              value={compRowData.lrdate}
              // onChange={}
              id="lrDate"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="seEmail"
              label="SE Email"
              variant="filled"
              type="text"
              value={compRowData.seemail}
              // onChange={}
              id="seEmail"
            />
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <TextField
              className=""
              fullWidth
              disabled
              name="remarks"
              label="Remarks"
              variant="filled"
              type="text"
              multiline="true"
              minRows="4"
              maxRows="4"
              value={compRowData.remarks}
              // onChange={}
              id="remarks"
            />
          </Grid>
        </Grid>

        {createCompAttchments.length > 0 ? (
          <DisplayMultipleAttachment tableData={createCompAttchments} />
        ) : (
          <></>
        )}

        <DialogTitle id="responsive-dialog-title">
          Close Complaint Details
        </DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="acceptance"
              label="Acceptance"
              variant="filled"
              type="text"
              value={compRowData.acceptance}
              id="acceptance"
            />
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <TextField
              className=""
              fullWidth
              disabled
              name="closingRemarks"
              label="Closing Remarks"
              variant="filled"
              type="text"
              multiline="true"
              minRows="4"
              maxRows="4"
              value={compRowData.closingRemarks}
              id="closingRemarks"
            />
          </Grid>

          {compRowData.closeComplaintAttachment != null ? (
            <Grid item xs={12} sm={4} md={3}>
              <div>
                <Button
                  className="pt-button--primary bulk-upload-submit-btn"
                  href={compRowData.closeComplaintAttachment}
                  color="transparent"
                  target="_blank"
                  download
                >
                  Download Attachment
                </Button>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </div> */}

      {/* <div className="accordionCont paddingBtm borderBtm">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>MLFB Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MaterialTable
              title="User List"
              columns={closeCompDtlColumn}
              data={compRowData.invoiceDetails}
              icons={tableIconsInputScreen}
              //isLoading={isLoader}
              options={tableOptions}
            />
          </AccordionDetails>
        </Accordion>
      </div> */}

      {/* <div>
        <DialogTitle id="responsive-dialog-title">Delegate To</DialogTitle>
      </div>

      <Grid container spacing={2} className="mt-05">
        <Grid item xs={12} sm={4} md={3} className="customerField">
          <Autocomplete
            options={delegateUser}
            getOptionLabel={(option) => {
              if (option.firstName != undefined)
                return `${option.firstName} ${option.lastName}`;
              else return "";
            }}
            id="debug"
            clearOnEscape={true}
            debug
            value={delegateUserUI}
            onChange={delegateUserChange}
            renderInput={(params) => (
              <TextField {...params} label="Delagate User" margin="none" />
            )}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          className="jc-center d-flex mt-1 buttonContainer marginBtm"
        >
          <Grid item xs={10} sm={4} md={3}>
            <Button
              fullWidth
              onClick={handleDelegateSubmit}
              className="pt-button--primary mt-1"
            >
              Delegate
            </Button>
          </Grid>

          <Grid item xs={10} sm={4} md={3}>
            <Button
              fullWidth
              onClick={handleCancelSubmit}
              className="pt-button--secondary mt-1"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid> */}

      <SnackbarCustom
        open={open}
        message={snackMessage}
        alertType={alertType}
        handleClose={handleClose}
      />
      {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
    </>
  );
}
